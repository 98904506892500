import React from "react";
import HomeEightCard from "../../Components/home-eight/HomeEightCard";
import Heighticon1 from "../../Assets/Heighticon1.png";
import Heighticon2 from "../../Assets/Heighticon2.png";
import Heighticon3 from "../../Assets/Heighticon3.png";
import Heighticon4 from "../../Assets/Heighticon4.png";
import Heighticon5 from "../../Assets/Heighticon5.png";
import Heighticon6 from "../../Assets/Heighticon6.png";
import htwentyhero from "../../Assets/htwentyhero.png";
import twentytimage from "../../Assets/twentytimage.png";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import AppLogo from "../../Components/app-logo";
import { socials } from "../../Components/Footer";
import HomeTwentyNav from "../../Components/home-twenty/HomeTwentyNav";

const HomeTwenty = () => {
  const cardData = [
    {
      image: Heighticon1,
      title: "Data Service",
      text: `${process.env.REACT_APP_AGENT_NAME} is a reliable and affordable platform for purchasing affordable data bundles for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo.`,
    },
    {
      image: Heighticon2,
      title: "Airtime Pin Services",
      text: `${process.env.REACT_APP_AGENT_NAME} offers convenient and rapid airtime top-up services at competitive rates. Our low charges and quick recharge services make it easy for customers to top up their airtime.`,
    },
    {
      image: Heighticon3,
      title: "Airtime Services",
      text: `${process.env.REACT_APP_AGENT_NAME} offers convenient and rapid airtime top-up services at competitive rates. Our affordable prices and quick recharge services make it easy for customers to top up their airtime for all networks.`,
    },
    {
      image: Heighticon4,
      title: "Electricty",
      text: "If you want to stay on top of your finances, then you can make use of our pocket-friendly electricity payment feature. It saves time and money with our streamlined payment tools.",
    },
    {
      image: Heighticon5,
      title: "Cable Subscription",
      text: `At ${process.env.REACT_APP_AGENT_NAME}, We offer discounted rates for renewing your DSTV, GOTV, and Startimes subscriptions, allowing you to save money on your cable service. Our convenient access to subscription renewal saves you time and effort in obtaining your cable service`,
    },
    {
      image: Heighticon6,
      title: "Education",
      text: `${process.env.REACT_APP_AGENT_NAME}  is your go-to source for various examination scratch cards pins, including Waec, Neco, and Nabteb examinations`,
    },
  ];

  return (
    <div className=" bg-heightcpl overflow-x-hidden  h-full">
      <HomeTwentyNav />

      <div
        name="home"
        className="lg:flex md:flex grid items-center pt-28 mx-auto w-full h-full"
      >
        <div className=" lg:w-2/5  lg:pl-28 md:px-16 px-8 ">
          <div>
            <p
              data-aos="fade-right"
              data-aos-duration="3000"
              className=" lg:text-[62px] md:text-[48px] text-[48px] font-bold font-DM+Sans text-white Sans"
            >
              Getting Bills Paid without being
              <div className=" text-[#19B832] font-bold lg:text-[62px] md:text-[48px] text-[48px]">
                Frustrated
              </div>
            </p>
            <p className=" lg:text-[16px] md:text-[12px] text-[10px] font-DM_Sans  text-white">
              In a few clicks, buy data to keep surfing the internet. You can
              buy whatever size of data plan for whichever network you desire.
              Get Started!
            </p>
            <div className=" flex items-center space-x-5">
              <RouterLink
                to="/login"
                className=" bg-[#19B832] no-underline text-black rounded-3xl px-4 py-2"
              >
                Get Started
              </RouterLink>
              <RouterLink
                to="/login"
                className=" flex items-center no-underline text-black justify-center bg-white rounded-3xl px-4 py-2"
              >
                Learn More{" "}
                <img
                  className=" w-6 h-auto "
                  src={require("../../Assets/Heightarrow.png")}
                  alt=""
                />
              </RouterLink>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-left"
          data-aos-duration="3000"
          className=" lg:w-3/5 flex justify-end"
        >
          <img
            className="  w-[600px] lg:h-[566.09px] lg:mt-0 md:mt-20 mt-20"
            src={htwentyhero}
            alt=""
          />
        </div>
      </div>
      <div className=" relative top-[-800px] h-3 mt-10 lg:px-28 md:px-16 px-8 mx-auto">
        <img src={require("../../Assets/HeightGroup.png")} alt="" />
      </div>

      {/* Review */}
      <div
        name="about"
        className=" lg:flex md:grid grid justify-between gap-10 mt-10 items-center lg:px-28 md:px-16 px-8 mx-auto"
      >
        <div
          data-aos="fade-right"
          data-aos-duration="3000"
          className=" lg:w-[45%] md:w-full w-full flex justify-center items-center"
        >
          <div>
            <img src={twentytimage} alt="" />
          </div>
          <div className="grid items-center">
            <div>
              <img
                className=" w-10 h-10"
                src={require("../../Assets/Heightquotedown.png")}
                alt=""
              />
            </div>

            <p className=" lg:text-xl font-DM_Sans md:text-[16px] text-[16px] text-white">
              I was hesitant to switch to an online platform for my
              subscriptions initially, but {process.env.REACT_APP_AGENT_NAME}{" "}
              has exceeded my expectations.
            </p>
          </div>
        </div>

        <div className="grid lg:w-[28%] md:w-full w-full">
          <p className=" text-white">Latest review</p>
          <div className=" flex items-center">
            <div>
              <img
                className="lg:w-[230px] lg:h-[48px] md:w-[150px] md:h-[30] w-[150px] h-[30]"
                src={require("../../Assets/HomeFourAvatar.png")}
                alt=""
              />
            </div>

            <div className=" flex items-center">
              <p className=" w-[106px] h-[32px] lg:mt-5 md:mt-5 mt-5 font-bold text-white">
                50K <span className=" text-[#19B832]">+</span>
              </p>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-left"
          data-aos-duration="3000"
          className=" lg:w-[27%] md:w-full w-full flex items-center lg:mt-0 md:mt-10 lg:justify-end"
        >
          <div className=" grid text-white">
            <div>
              <p className=" lg:text-[20px] md:textr-[15px] text-[15px]">
                Ratings
              </p>
            </div>
            <div className="flex space-x-2 items-center">
              <div className="">
                <img
                  className=" lg:w-10 md:w-10 w-10 lg:h-10 md:h-6 h-7 relative top-[-10px]"
                  src={require("../../Assets/HeightStar.png")}
                  alt=""
                />
              </div>

              <div>
                <p className=" font-bold lg:text-[32px] md:text-[26] text-[22px] ">
                  4.9
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" relative top-[-300px] h-3 mt-10 lg:px-28 md:px-16 px-8 mx-auto">
        <img src={require("../../Assets/HeightGroup.png")} alt="" />
      </div>

      <div className="lg:flex md:grid grid lg:space-x-20 md:space-x-0 space-x-0 justify-center mt-20 lg:px-28 md:px-16 px-8 mx-auto">
        <div
          data-aos="fade-right"
          data-aos-duration="3000"
          className=" grid lg:w-1/2 md:w-full w-full"
        >
          <div className=" grid justify-center text-white">
            <p className=" lg:text-[36px] md:text-[24px] font-bold text-[20px]">
              Take Control of Your Subscriptions with{" "}
              <span className=" text-[#19B832] lg:text-[36px] font-bold md:text-[24px] text-[20px]">
                {process.env.REACT_APP_AGENT_NAME}
              </span>{" "}
            </p>
            <p>
              Empower Your Financial Journey with the Ultimate Solution for
              Convenience and experience the ease and security of managing your
              finances with our innovative and comprehensive solution.
            </p>
          </div>
          <div>
            <img src={require("../../Assets/HeightiPhone12.png")} alt="" />
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="3000"
          className=" grid lg:w-1/2 md:w-full w-full"
        >
          <div className=" bg-[#19B832] p-6 rounded-2xl w-full h-auto">
            <p className=" text-[20px] font-bold text-black">
              SECURED TRANSACTIONS
            </p>
            <p className=" text-black">
              We take the security of your account seriously, so,we are
              committed to preventing data loss.
            </p>
          </div>

          <div className=" px-6 pt-2 w-full h-auto ">
            <p className=" text-[20px] font-bold text-white">
              OPTIMUM SATISFACTION
            </p>
            <p className=" text-white">
              Periodically, {process.env.REACT_APP_AGENT_NAME} offers the best
              discounts and special deals on all of our services, including
              data, airtime, and cable subscriptions. Stay tuned for updates on
              our special offers and take advantage of the opportunity to save
              on these services.
            </p>
          </div>

          <div className=" px-6 w-full h-auto">
            <p className=" text-[20px] font-bold text-white">QUICK DELIVERY</p>
            <p className=" text-white">
              Our transaction delivery is super-fast. We understand that you
              need it as urgent as possible.
            </p>
          </div>

          <div className=" px-6 w-full h-auto ">
            <p className=" text-[20px] font-bold text-white">
              Exceptional Customer Support
            </p>
            <p className=" text-white">
              Our team is available 24/7 to assist you with any questions or
              concerns.
            </p>
          </div>
        </div>
      </div>
      <div className=" relative top-[-600px] h-3 mt-10 lg:px-28 md:px-16 px-8 mx-auto">
        <img src={require("../../Assets/HeightGroup.png")} alt="" />
      </div>

      <div
        data-aos="fade-left"
        data-aos-duration="3000"
        name="product"
        className="mt-10 lg:px-28 md:px-16 px-8 mx-auto"
      >
        <div className=" grid justify-center items-center text-white">
          <div className="flex justify-center">
            <p className=" lg:text-[32px] md:text-[26px] text-[24px]">
              Our{" "}
              <span className=" text-[#19B832] lg:text-[32px] md:text-[26px] text-[24px]">
                services
              </span>{" "}
              Especially for you
            </p>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="3000"
            className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 justify-center mx-auto"
          >
            {cardData.map((card, index) => (
              <HomeEightCard
                key={index}
                image={card.image}
                imagee={card.imagee}
                title={card.title}
                text={card.text}
                buttonText={card.buttonText}
                arrow={card.arrow}
              />
            ))}
          </div>
        </div>
      </div>

      <div className=" grid mt-20 justify-center items-center lg:px-28 md:px-16 px-8 mx-auto">
        <div className=" text-white">
          <p className=" lg:text-[20px] md:text-[14px] text-[12px] text-center">
            Trusted by 4,000+ companies
          </p>
        </div>

        <div className="overflow-hidden">
          <div className="flex animate-marquee items-center">
            <img
              className=" lg:h-[48px] lg:w-[110px] md:h-[30px] md:w-[70px] h-[20px] w-[60px] text-white"
              src={require("../../Assets/Netflix.png")}
              alt=""
            />
            <img
              className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
              src={require("../../Assets/Canva.png")}
              alt=""
            />
            <img
              className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
              src={require("../../Assets/Adobe.png")}
              alt=""
            />
            <img
              className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
              src={require("../../Assets/Grammaly.png")}
              alt=""
            />
            <img
              className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
              src={require("../../Assets/Airbnb.png")}
              alt=""
            />
            <img
              className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
              src={require("../../Assets/Amazon.png")}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="mt-20 lg:px-28 md:px-16 px-8 mx-auto">
        <div className="grid justify-center lg:px-12 text-white">
          <div className=" grid justify-center">
            <p className=" lg:text-[36px] md:text-[28px] text-[24px]">
              Frequently Asked{" "}
              <span className=" text-[#19B832] lg:text-[36px] md:text-[28px] text-[24px]">
                Questions
              </span>
            </p>
          </div>
          <div className="grid justify-center ">
            <p>Everything you need to know about the product and billing.</p>
          </div>

          <div
            data-aos="fade-right"
            data-aos-duration="3000"
            className=" grid justify-center  lg:px-32 md:p-16 p-10"
          >
            <div className="grid ">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-[20px]">
                    What is {process.env.REACT_APP_AGENT_NAME}?
                  </p>
                </div>
                <div>
                  <img
                    className="w-5 h-5"
                    src={require("../../Assets/Heightarrowcircleup.png")}
                    alt=""
                  />
                </div>
              </div>
              <div>
                <p>
                  {process.env.REACT_APP_AGENT_NAME} is a modern banking
                  solution that allows you to manage your finances from your
                  phone or computer. Our user-friendly app and secure online
                  platform provide you with a convenient and efficient way to
                  access all of your financial services in one place.
                </p>
              </div>
            </div>
            <div className=" text-white">
              <hr className=" w-full text-white text-2xl" />
            </div>
            <div className=" flex justify-between items-center">
              <div>
                <p>Is {process.env.REACT_APP_AGENT_NAME} secure?</p>
              </div>
              <div>
                <img
                  className=" w-5 h-5"
                  src={require("../../Assets/Heightarrowcircleup.png")}
                  alt=""
                />
              </div>
            </div>

            <div className=" text-white">
              <hr className=" w-full text-white text-2xl" />
            </div>

            <div className=" flex justify-between items-center">
              <div>
                <p>
                  What services does {process.env.REACT_APP_AGENT_NAME} offer?
                </p>
              </div>
              <div>
                <img
                  className=" w-5 h-5"
                  src={require("../../Assets/Heightarrowcircleup.png")}
                  alt=""
                />
              </div>
            </div>

            <div className=" text-white">
              <hr className=" w-full text-white text-2xl" />
            </div>

            <div className=" flex justify-between items-center">
              <div>
                <p>Is {process.env.REACT_APP_AGENT_NAME} free to use?</p>
              </div>
              <div>
                <img
                  className=" w-5 h-5"
                  src={require("../../Assets/Heightarrowcircleup.png")}
                  alt=""
                />
              </div>
            </div>

            <div className=" text-white">
              <hr className=" w-full text-white text-2xl" />
            </div>

            <div className=" flex justify-between items-center">
              <div>
                <p>
                  Can I use {process.env.REACT_APP_AGENT_NAME} outside of the
                  country?
                </p>
              </div>
              <div>
                <img
                  className=" w-5 h-5"
                  src={require("../../Assets/Heightarrowcircleup.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" relative top-[-800px] h-3 mt-10 lg:px-28 md:px-16 px-8 mx-auto">
        <img src={require("../../Assets/HeightGroup.png")} alt="" />
      </div>

      {/* footer */}

      <div
        name="contact"
        className=" bg-[#19B832] mt-20 lg:px-28 py-5 md:px-16 px-8 mx-auto"
      >
        <div
          data-aos="fade-left"
          data-aos-duration="3000"
          className=" lg:flex md:grid grid justify-between items-center text-black"
        >
          <div className=" grid">
            <div className=" flex items-center  space-x-2">
              <div>
                <AppLogo type="dark" height="50px" />
              </div>
              <div>
                <p className=" font-bold lg:mt-3 md:mt-3 mt-3">
                  {process.env.REACT_APP_AGENT_NAME}
                </p>
              </div>
            </div>

            <div className="flex">
              <ul className=" flex space-x-5">
                <Link to="home">Home</Link>
                <Link to="about">About</Link>
                <Link to="product">Features</Link>
                <Link>Price</Link>
                <Link to="contact">Contact</Link>
              </ul>
            </div>
          </div>

          <div className=" grid  relative lg:right-[0px]">
            {/* <div>
							<p>Stay up to date</p>
						</div>
						<div className="">
							<input
								className=" border-white bg-black rounded-full px-2 py-4 w-[350px] h-[50px]"
								placeholder=" Enter your email"
								type="text"
							/>
							<button className=" bg-[#19B832] relative lg:left-[-109.5px] lg:top-0 md:bottom-[0px] bottom-[49.5px] lg:right-0 md:left-[-110px] left-[239px] rounded-full border-none w-[109px] h-[48px]">
								{" "}
								Subscribe
							</button>
						</div> */}
            <div className="flex items-center space-x-3">
              {/* <img
								className=" w-auto h-4 mb-3"
								src={require("../../Assets/HsevenTline.png")}
								alt=""
							/> */}
              <p className="font-bold">Contact Us</p>
            </div>
            <div>
              {/* <p>+234 800 000 0000</p> */}
              <p>{process.env.REACT_APP_AGENT_TELEPHONE}</p>
              <p>{process.env.REACT_APP_AGENT_EMAIL}</p>
              <p>{process.env.REACT_APP_AGENT_ADDRESS}</p>
            </div>
            <ul className="list-group border-0 list-group-horizontal">
              {socials.map((item, index) => (
                <li
                  key={index}
                  className="list-group-item border-0 bg-transparent text-white d-flex align-items-center"
                >
                  <a
                    className="text-white text-decoration-none fontReduce"
                    target={"_blank"}
                    rel="noreferrer"
                    href={
                      item?.type === "mail" && item?.url
                        ? `mailto:${item?.url}`
                        : item?.url || "#"
                    }
                  >
                    {item?.icon}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className=" flex justify-center items-center">
          <hr className=" text-black text-2xl w-full" />
        </div>

        <div className=" lg:flex md:grid grid justify-between items-center">
          <div className="">
            © {new Date().getFullYear()} {process.env.REACT_APP_AGENT_NAME}. All
            rights reserved.
          </div>

          <div>
            <ul className=" flex space-x-5">
              <li>Terms</li>
              <li>Privacy</li>
              <li>Cookies</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTwenty;

// import React from "react";

// const HomeTwenty = () => {
//   return (
//     <div>
//       <p>Home twenty</p>
//     </div>
//   );
// };

// export default HomeTwenty;
