export const TOKEN = "AFFILIATE_WEBSITE";
export const TOKEN_2FA = "AFFILIATE_2FA";

export const LOGOUT = "LOGOUT";
export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const LOGIN_USER_2FA = "LOGIN_USER_2FA";
export const GET_USER = "GET_USER";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const GET_USER_LOADING = "GET_USER_LOADING";

// Data
export const GET_DATA = "GET_DATA";
export const GET_DATA_FAIL = "GET_DATA_FAIL";
export const GET_DATA_DIRECT = "GET_DATA_DIRECT";
export const GET_DATA_DIRECT_FAIL = "GET_DATA_DIRECT_FAIL";
export const GET_DATA_LOADING = "GET_DATA_LOADING";
export const UPDATE_DATA = "UPDATE_DATA";
export const ADD_DATA = "ADD_DATA";
export const ADD_DATA_FAIL = "ADD_DATA_FAIL";
export const GET_CREATE_DATA = "GET_CREATE_DATA";
export const GET_CREATE_DATA_FAIL = "GET_CREATE_DATA_FAIL";
export const CREATE_DATA = "CREATE_DATA";
export const CREATE_DATA_FAIL = "CREATE_DATA_FAIL";
export const DELETE_DATA = "DELETE_DATA";
export const SEARCH_DATA = "SEARCH_DATA";
export const SEARCH_DATA_LOADING = "SEARCH_DATA_LOADING";
export const SEARCH_DATA_FAIL = "SEARCH_DATA_FAIL";
export const SEARCH_DATA_RELOAD = "SEARCH_DATA_RELOAD";

// Airtime
export const GET_AIRTIME = "GET_AIRTIME";
export const GET_AIRTIME_FAIL = "GET_AIRTIME_FAIL";
export const ADD_AIRTIME = "ADD_AIRTIME";
export const ADD_AIRTIME_FAIL = "ADD_AIRTIME_FAIL";
export const UPDATE_AIRTIME = "UPDATE_AIRTIME";
export const DELETE_AIRTIME = "DELETE_AIRTIME";
export const GET_AIRTIME_LOADING = "GET_AIRTIME_LOADING";
export const SEARCH_AIRTIME = "SEARCH_AIRTIME";
export const SEARCH_AIRTIME_LOADING = "SEARCH_AIRTIME_LOADING";
export const SEARCH_AIRTIME_FAIL = "SEARCH_AIRTIME_FAIL";
export const SEARCH_AIRTIME_RELOAD = "SEARCH_AIRTIME_RELOAD";

export const GET_AIRTIME_PIN = "GET_AIRTIME_PIN";
export const GET_AIRTIME_PIN_FAIL = "GET_AIRTIME_PIN_FAIL";
export const ADD_AIRTIME_PIN = "ADD_AIRTIME_PIN";
export const ADD_AIRTIME_PIN_FAIL = "ADD_AIRTIME_PIN_FAIL";
export const UPDATE_AIRTIME_PIN = "UPDATE_AIRTIME_PIN";
export const DELETE_AIRTIME_PIN = "DELETE_AIRTIME_PIN";
export const GET_AIRTIME_PIN_LOADING = "GET_AIRTIME_PIN_LOADING";
export const SEARCH_AIRTIME_PIN = "SEARCH_AIRTIME_PIN";
export const SEARCH_AIRTIME_PIN_LOADING = "SEARCH_AIRTIME_PIN_LOADING";
export const SEARCH_AIRTIME_PIN_FAIL = "SEARCH_AIRTIME_PIN_FAIL";
export const SEARCH_AIRTIME_PIN_RELOAD = "SEARCH_AIRTIME_PIN_RELOAD";

// Education
export const GET_EDUCATION = "GET_EDUCATION";
export const GET_EDUCATION_FAIL = "GET_EDUCATION_FAIL";
export const GET_EDUCATION_DIRECT = "GET_EDUCATION_DIRECT";
export const GET_EDUCATION_DIRECT_FAIL = "GET_EDUCATION_DIRECT_FAIL";
export const UPDATE_EDUCATION = "UPDATE_EDUCATION";
export const ADD_EDUCATION = "ADD_EDUCATION";
export const DELETE_EDUCATION = "DELETE_EDUCATION";
export const GET_EDUCATION_LOADING = "GET_EDUCATION_LOADING";
export const ADD_EDUCATION_FAIL = "ADD_EDUCATION_FAIL";
export const SEARCH_EDUCATION = "SEARCH_EDUCATION";
export const SEARCH_EDUCATION_LOADING = "SEARCH_EDUCATION_LOADING";
export const SEARCH_EDUCATION_FAIL = "SEARCH_EDUCATION_FAIL";
export const SEARCH_EDUCATION_RELOAD = "SEARCH_EDUCATION_RELOAD";
export const GET_EDUCATION_TO_BUY = "GET_EDUCATION_TO_BUY";
export const GET_EDUCATION_TO_BUY_FAIL = "GET_EDUCATION_TO_BUY_FAIL";
export const UPDATE_EDUCATION_BUNDLE = "UPDATE_EDUCATION_BUNDLE";
export const ADD_EDUCATION_BUNDLE = "ADD_EDUCATION_BUNDLE";
export const ADD_EDUCATION_BUNDLE_FAIL = "ADD_EDUCATION_BUNDLE_FAIL";
export const DELETE_EDUCATION_BUNDLE = "DELETE_EDUCATION_BUNDLE";

// Electricity
export const GET_ELECTRICITY = "GET_ELECTRICITY";
export const GET_ELECTRICITY_FAIL = "GET_ELECTRICITY_FAIL";
export const GET_ELECTRICITY_DIRECT = "GET_ELECTRICITY_DIRECT";
export const GET_ELECTRICITY_DIRECT_FAIL = "GET_ELECTRICITY_DIRECT_FAIL";
export const UPDATE_ELECTRICITY = "UPDATE_ELECTRICITY";
export const ADD_ELECTRICITY = "ADD_ELECTRICITY";
export const DELETE_ELECTRICITY = "DELETE_ELECTRICITY";
export const GET_ELECTRICITY_LOADING = "GET_ELECTRICITY_LOADING";
export const ADD_ELECTRICITY_FAIL = "ADD_ELECTRICITY_FAIL";
export const SEARCH_ELECTRICITY = "SEARCH_ELECTRICITY";
export const SEARCH_ELECTRICITY_LOADING = "SEARCH_ELECTRICITY_LOADING";
export const SEARCH_ELECTRICITY_FAIL = "SEARCH_ELECTRICITY_FAIL";
export const SEARCH_ELECTRICITY_RELOAD = "SEARCH_ELECTRICITY_RELOAD";

export const GET_CABLE = "GET_CABLE";
export const GET_CABLE_FAIL = "GET_CABLE_FAIL";
export const GET_CABLE_DIRECT_PACKAGE = "GET_CABLE_DIRECT_PACKAGE";
export const GET_CABLE_DIRECT_PACKAGE_FAIL = "GET_CABLE_DIRECT_PACKAGE_FAIL";
export const GET_CABLE_DIRECT_TYPE = "GET_CABLE_DIRECT_TYPE";
export const GET_CABLE_DIRECT_TYPE_FAIL = "GET_CABLE_DIRECT_TYPE_FAIL";
export const UPDATE_CABLE = "UPDATE_CABLE";
export const ADD_CABLE = "ADD_CABLE";
export const DELETE_CABLE = "DELETE_CABLE";
export const GET_CABLE_LOADING = "GET_CABLE_LOADING";
export const ADD_CABLE_FAIL = "ADD_CABLE_FAIL";
export const SEARCH_CABLE = "SEARCH_CABLE";
export const SEARCH_CABLE_LOADING = "SEARCH_CABLE_LOADING";
export const SEARCH_CABLE_FAIL = "SEARCH_CABLE_FAIL";
export const SEARCH_CABLE_RELOAD = "SEARCH_CABLE_RELOAD";

export const GET_NETWORK = "GET_NETWORK";
export const GET_NETWORK_FAIL = "GET_NETWORK_FAIL";
export const GET_NETWORK_LOADING = "GET_NETWORK_LOADING";
export const GET_BANKS = "GET_BANKS";
export const GET_BANKS_FAIL = "GET_BANKS_FAIL";

export const GET_MANUAL_BANKS = "GET_MANUAL_BANKS";
export const ADD_MANUAL_BANKS = "ADD_MANUAL_BANKS";
export const ADD_MANUAL_BANKS_FAIL = "ADD_MANUAL_BANKS_FAIL";
export const DELETE_MANUAL_BANKS = "DELETE_MANUAL_BANKS";

export const GET_FAQS = "GET_FAQS";
export const ADD_FAQS = "ADD_FAQS";
export const UPDATE_FAQS = "UPDATE_FAQS";
export const ADD_FAQS_FAIL = "ADD_FAQS_FAIL";
export const DELETE_FAQS = "DELETE_FAQS";

// ERRORS
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_ERRORS_TEXT = "GET_ERRORS_TEXT";

// WALLET
export const GET_WALLET = "GET_WALLET";
export const GET_WALLET_FAIL = "GET_WALLET_FAIL";
export const GET_WALLET_STAT = "GET_WALLET_STAT";
export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";
export const GET_WALLET_BALANCE_FAIL = "GET_WALLET_BALANCE_FAIL";
export const GET_WALLET_DETAILS = "GET_WALLET_DETAILS";
export const GET_WALLET_DETAILS_FAIL = "GET_WALLET_DETAILS_FAIL";
export const GET_COMMISSION = "GET_COMMISSION";
export const MOVE_COMMISSION = "MOVE_COMMISSION";
export const MOVE_COMMISSION_FAIL = "MOVE_COMMISSION_FAIL";
export const GET_REFERRAL = "GET_REFERRAL";
export const GET_ALL_REFERRAL = "GET_ALL_REFERRAL";
export const GET_GENERAL_REFERRAL = "GET_GENERAL_REFERRAL";
export const MOVE_REFERRAL = "MOVE_REFERRAL";
export const MOVE_REFERRAL_FAIL = "MOVE_REFERRAL_FAIL";
export const GET_BONUS = "GET_BONUS";
export const GET_ALL_BONUS = "GET_ALL_BONUS";
export const GET_ALL_MANUAL = "GET_ALL_MANUAL";
export const GIVE_BONUS = "GIVE_BONUS";
export const GIVE_BONUS_FAIL = "GIVE_BONUS_FAIL";
export const MOVE_BONUS = "MOVE_BONUS";
export const MOVE_BONUS_FAIL = "MOVE_BONUS_FAIL";
export const TRANSFER_FUND = "TRANSFER_FUND";
export const TRANSFER_FUND_FAIL = "TRANSFER_FUND_FAIL";
export const ADD_FUND = "ADD_FUND";
export const ADD_FUND_FAIL = "ADD_FUND_FAIL";
export const FUND_WALLET = "FUND_WALLET";
export const FUND_WALLET_FAIL = "FUND_WALLET_FAIL";
export const UPDATE_WALLET = "UPDATE_WALLET";
export const UPDATE_WALLET_FAIL = "UPDATE_WALLET_FAIL";
export const FUND_WALLET_FLUTTERWAVE = "FUND_WALLET_FLUTTERWAVE";
export const FUND_WALLET_FLUTTERWAVE_FAIL = "FUND_WALLET_FLUTTERWAVE_FAIL";
export const UPDATE_WALLET_FLUTTERWAVE = "UPDATE_WALLET_FLUTTERWAVE";
export const UPDATE_WALLET_FLUTTERWAVE_FAIL = "UPDATE_WALLET_FLUTTERWAVE_FAIL";
export const SEARCH_WALLET = "SEARCH_WALLET";
export const SEARCH_WALLET_LOADING = "SEARCH_WALLET_LOADING";
export const SEARCH_WALLET_FAIL = "SEARCH_WALLET_FAIL";
export const SEARCH_WALLET_RELOAD = "SEARCH_WALLET_RELOAD";

export const GET_FULL_USERS = "GET_FULL_USERS";
export const GET_FULL_USERS_FAIL = "GET_FULL_USERS_FAIL";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_LOADING = "GET_ALL_USERS_LOADING";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";
export const SEARCH_ALL_USERS = "SEARCH_ALL_USERS";
export const SEARCH_ALL_USERS_LOADING = "SEARCH_ALL_USERS_LOADING";
export const SEARCH_ALL_USERS_FAIL = "SEARCH_ALL_USERS_FAIL";
export const SEARCH_ALL_USERS_RELOAD = "SEARCH_ALL_USERS_RELOAD";

export const GET_CARDS = "GET_CARDS";
export const GET_CARDS_FAIL = "GET_CARDS_FAIL";

export const MANUAL_TRANSACTION = "MANUAL_TRANSACTION";
export const MANUAL_TRANSACTION_FAIL = "MANUAL_TRANSACTION_FAIL";
export const MANUAL_DEBIT = "MANUAL_DEBIT";
export const MANUAL_DEBIT_FAIL = "MANUAL_DEBIT_FAIL";

export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const GET_ALL_TRANSACTIONS_FAIL = "GET_ALL_TRANSACTIONS_FAIL";
export const GET_MONTH_TRANSACTIONS = "GET_MONTH_TRANSACTIONS";
export const GET_MONTH_TRANSACTIONS_FAIL = "GET_MONTH_TRANSACTIONS_FAIL";
export const GET_DAY_TRANSACTIONS = "GET_DAY_TRANSACTIONS";
export const GET_DAY_TRANSACTIONS_FAIL = "GET_DAY_TRANSACTIONS_FAIL";
export const GET_MY_TRANSACTIONS = "GET_MY_TRANSACTIONS";
export const GET_MY_TRANSACTIONS_FAIL = "GET_MY_TRANSACTIONS_FAIL";
export const GET_MY_MONTH_TRANSACTIONS = "GET_MY_MONTH_TRANSACTIONS";
export const GET_MY_MONTH_TRANSACTIONS_FAIL = "GET_MY_MONTH_TRANSACTIONS_FAIL";
export const GET_MY_DAY_TRANSACTIONS = "GET_MY_DAY_TRANSACTIONS";
export const GET_MY_DAY_TRANSACTIONS_FAIL = "GET_MY_DAY_TRANSACTIONS_FAIL";
export const SEARCH_TRANSACTION = "SEARCH_TRANSACTION";
export const SEARCH_TRANSACTION_LOADING = "SEARCH_TRANSACTION_LOADING";
export const SEARCH_TRANSACTION_FAIL = "SEARCH_TRANSACTION_FAIL";
export const SEARCH_TRANSACTION_RELOAD = "SEARCH_TRANSACTION_RELOAD";
export const SEARCH_MY_TRANSACTION = "SEARCH_MY_TRANSACTION";
export const SEARCH_MY_TRANSACTION_LOADING = "SEARCH_MY_TRANSACTION_LOADING";
export const SEARCH_MY_TRANSACTION_FAIL = "SEARCH_MY_TRANSACTION_FAIL";
export const SEARCH_MY_TRANSACTION_RELOAD = "SEARCH_MY_TRANSACTION_RELOAD";

export const GET_DATA_TRANSACTIONS = "GET_DATA_TRANSACTIONS";
export const GET_DATA_TRANSACTIONS_FAIL = "GET_DATA_TRANSACTIONS_FAIL";

export const GET_SETTINGS = "GET_SETTINGS";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const UPDATE_SETTINGS_FAIL = "UPDATE_SETTINGS_FAIL";

export const GET_USECASE = "GET_USECASE";
export const UPDATE_USECASE = "UPDATE_USECASE";
export const UPDATE_USECASE_FAIL = "UPDATE_USECASE_FAIL";

export const GET_NOTIFICATONS = "GET_NOTIFICATONS";
export const GET_NOTIFICATONS_FAIL = "GET_NOTIFICATONS_FAIL";
export const GET_MY_NOTIFICATONS = "GET_MY_NOTIFICATONS";
export const GET_MY_NOTIFICATONS_FAIL = "GET_MY_NOTIFICATONS_FAIL";
export const ADD_NOTIFICATONS = "ADD_NOTIFICATONS";
export const ADD_NOTIFICATONS_FAIL = "ADD_NOTIFICATONS_FAIL";
export const UPDATE_NOTIFICATONS = "UPDATE_NOTIFICATONS";
export const DELETE_NOTIFICATONS = "DELETE_NOTIFICATONS";

export const GENERATE_VIRTUAL = "GENERATE_VIRTUAL";
export const GENERATE_VIRTUAL_FAIL = "GENERATE_VIRTUAL_FAIL";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";

export const GET_PROVIDER_BALANCE = "GET_PROVIDER_BALANCE";
export const GET_PROVIDER_BALANCE_FAIL = "GET_PROVIDER_BALANCE_FAIL";

export const SET_SUCCESS = "SET_SUCCESS";
export const SET_SUCCESS_FAIL = "SET_SUCCESS_FAIL";

export const GET_AIRTIME_CONVERTER = "GET_AIRTIME_CONVERTER";
export const GET_AIRTIME_CONVERTER_FAIL = "GET_AIRTIME_CONVERTER_FAIL";
export const ADD_AIRTIME_CONVERTER = "ADD_AIRTIME_CONVERTER";
export const ADD_AIRTIME_CONVERTER_FAIL = "ADD_AIRTIME_CONVERTER_FAIL";
export const GET_CONVERTER_NUMBER = "GET_CONVERTER_NUMBER";
export const GET_CONVERTER_NUMBER_FAIL = "GET_CONVERTER_NUMBER_FAIL";
export const ADD_CONVERTER_NUMBER = "ADD_CONVERTER_NUMBER";
export const ADD_CONVERTER_NUMBER_FAIL = "ADD_CONVERTER_NUMBER_FAIL";
export const UPDATE_CONVERTER_NUMBER = "UPDATE_CONVERTER_NUMBER";
export const UPDATE_CONVERTER_NUMBER_FAIL = "UPDATE_CONVERTER_NUMBER_FAIL";
export const UPDATE_CONVERTER_DETAIL = "UPDATE_CONVERTER__DETAIL";
export const UPDATE_CONVERTER_DETAIL_FAIL = "UPDATE_CONVERTER_DETAIL_FAIL";
export const SEARCH_CONVERTER = "SEARCH_CONVERTER";
export const SEARCH_CONVERTER_LOADING = "SEARCH_CONVERTER_LOADING";
export const SEARCH_CONVERTER_FAIL = "SEARCH_CONVERTER_FAIL";
export const SEARCH_CONVERTER_RELOAD = "SEARCH_CONVERTER_RELOAD";

export const ACTIVATE_USER = "ACTIVATE_USER";
export const DELETE_USER = "DELETE_USER";
export const ACTIVATE_USER_FAIL = "ACTIVATE_USER_FAIL";

export const WALLET_HISTORY_USER = "WALLET_HISTORY_USER";
export const WALLET_HISTORY_USER_LOADING = "WALLET_HISTORY_USER_LOADING";
export const WALLET_HISTORY_USER_FAIL = "WALLET_HISTORY_USER_FAIL";

export const PURCHASE_HISTORY_USER = "PURCHASE_HISTORY_USER";
export const PURCHASE_HISTORY_USER_LOADING = "PURCHASE_HISTORY_USER_LOADING";
export const PURCHASE_HISTORY_USER_FAIL = "PURCHASE_HISTORY_USER_FAIL";

export const SEARCH_PENDING_HISTORY = "SEARCH_PENDING_HISTORY";
export const SEARCH_PENDING_HISTORY_LOADING = "SEARCH_PENDING_HISTORY_LOADING";
export const SEARCH_PENDING_HISTORY_FAIL = "SEARCH_PENDING_HISTORY_FAIL";
export const SEARCH_PENDING_HISTORY_RELOAD = "SEARCH_PENDING_HISTORY_RELOAD";
export const GET_PENDING_HISTORY = "GET_PENDING_HISTORY";
export const GET_PENDING_HISTORY_FAIL = "GET_PENDING_HISTORY_FAIL";
export const UPDATE_PENDING_HISTORY = "UPDATE_PENDING_HISTORY";
export const UPDATE_PENDING_HISTORY_FAIL = "UPDATE_PENDING_HISTORY_FAIL";

export const SEARCH_PENDING_HISTORY_VIRTUAL = "SEARCH_PENDING_HISTORY_VIRTUAL";
export const SEARCH_PENDING_HISTORY_VIRTUAL_LOADING =
	"SEARCH_PENDING_HISTORY_LOADING";
export const SEARCH_PENDING_HISTORY_VIRTUAL_FAIL =
	"SEARCH_PENDING_HISTORY_VIRTUAL_FAIL";
export const SEARCH_PENDING_HISTORY_VIRTUAL_RELOAD =
	"SEARCH_PENDING_HISTORY_VIRTUAL_RELOAD";
export const GET_PENDING_HISTORY_VIRTUAL = "GET_PENDING_HISTORY_VIRTUAL";
export const GET_PENDING_HISTORY_VIRTUAL_FAIL =
	"GET_PENDING_HISTORY_VIRTUAL_FAIL";
export const UPDATE_PENDING_HISTORY_VIRTUAL = "UPDATE_PENDING_HISTORY_VIRTUAL";
export const UPDATE_PENDING_HISTORY_VIRTUAL_FAIL =
	"UPDATE_PENDING_HISTORY_VIRTUAL_FAIL";

export const SEARCH_PENDING_HISTORY_CARD = "SEARCH_PENDING_HISTORY_CARD";
export const SEARCH_PENDING_HISTORY_CARD_LOADING =
	"SEARCH_PENDING_HISTORY_LOADING";
export const SEARCH_PENDING_HISTORY_CARD_FAIL =
	"SEARCH_PENDING_HISTORY_CARD_FAIL";
export const SEARCH_PENDING_HISTORY_CARD_RELOAD =
	"SEARCH_PENDING_HISTORY_CARD_RELOAD";
export const GET_PENDING_HISTORY_CARD = "GET_PENDING_HISTORY_CARD";
export const GET_PENDING_HISTORY_CARD_FAIL = "GET_PENDING_HISTORY_CARD_FAIL";
export const UPDATE_PENDING_HISTORY_CARD = "UPDATE_PENDING_HISTORY_CARD";
export const UPDATE_PENDING_HISTORY_CARD_FAIL =
	"UPDATE_PENDING_HISTORY_CARD_FAIL";

export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const DELETE_MULTIPLE_TRANSACTION = "DELETE_MULTIPLE_TRANSACTION";
export const DELETE_TRANSACTION_FAIL = "DELETE_TRANSACTION_FAIL";

export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const UPDATE_MULTIPLE_TRANSACTION = "UPDATE_MULTIPLE_TRANSACTION";
export const UPDATE_TRANSACTION_FAIL = "UPDATE_TRANSACTION_FAIL";

export const GET_UPGRADE = "GET_UPGRADE";
export const GET_UPGRADE_FAIL = "GET_UPGRADE_FAIL";
export const ADD_UPGRADE = "ADD_UPGRADE";
export const ADD_UPGRADE_FAIL = "ADD_UPGRADE_FAIL";
export const UPDATE_UPGRADE = "UPDATE_UPGRADE";

export const GENERATE_VIRTUAL_CEBIZ = "GENERATE_VIRTUAL_CEBIZ";
export const GENERATE_VIRTUAL_CEBIZ_FAIL = "GENERATE_VIRTUAL_CEBIZ_FAIL";

export const WALLET_PROVIDER_STAT = "WALLET_PROVIDER_STAT";
export const TRANSACTIONS_STAT = "TRANSACTIONS_STAT";
export const DATA_TRANSACTIONS_STAT = "DATA_TRANSACTIONS_STAT";
export const FIND_TRANSACTIONS_STAT = "FIND_TRANSACTIONS_STAT";

export const GET_MONNIFY = "GET_MONNIFY";
export const GET_MANUAL = "GET_MANUAL";
export const GET_PAYSTACK = "GET_PAYSTACK";
export const GET_FLUTTERWAVE = "GET_FLUTTERWAVE";
export const GET_PROVIDER_FAIL = "GET_PROVIDER_FAIL";
export const SEARCH_PROVIDER = "SEARCH_PROVIDER";
export const SEARCH_PROVIDER_LOADING = "SEARCH_PROVIDER_LOADING";
export const SEARCH_PROVIDER_FAIL = "SEARCH_PROVIDER_FAIL";
export const SEARCH_PROVIDER_RELOAD = "SEARCH_PROVIDER_RELOAD";

export const SEARCH_RELOAD = "SEARCH_RELOAD";

export const MANAGE_WALLET_PIN = "MANAGE_WALLET_PIN";

export const GET_DOC = "GET_DOC";
export const GENERATE_DOC = "GENERATE_DOC";
export const GENERATE_DOC_FAIL = "GENERATE_DOC_FAIL";

export const TRANSACTION_DETAILS = "TRANSACTION_DETAILS";
