// import React from "react";

// const HomeTwentyoneNav = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default HomeTwentyoneNav;

import React, { useState } from "react";
import { FaHamburger } from "react-icons/fa";
import { Link } from "react-scroll";
import AppLogo from "../app-logo";
import { Link as RouterLink } from "react-router-dom";

const HomeTwentyoneNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <nav className="fixed top-[-3px] left-0 right-0 z-20 bg-white p-8 shadow-md md:px-16 lg:px-28 flex items-center justify-between">
      <div className="text-black text-lg font-semibold">
        <AppLogo type="dark" height="50px" />
      </div>
      <div className="hidden md:flex space-x-10">
        <Link
          to="product"
          smooth={true}
          className="text-[#134806] cursor-pointer no-underline"
        >
          Product
        </Link>
        <Link
          to="about"
          smooth={true}
          className="text-[#134806] cursor-pointer no-underline"
        >
          About Us
        </Link>
        <Link
          to="contact"
          smooth={true}
          className="text-[#134806] cursor-pointer no-underline"
        >
          Contact Us
        </Link>
      </div>
      {/* <div>
        <RouterLink
          to="/register"
          className="px-4 py-2 bg-[#47AB18] rounded-3xl text-white no-underline"
        >
          Register
        </RouterLink>
      </div> */}
      <div className="md:hidden">
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="text-[#134806] focus:outline-none"
        >
          <FaHamburger />
        </button>
      </div>
      {menuOpen && (
        <div className="md:hidden absolute top-16 left-0 w-full bg-white p-4 space-y-8">
          <Link
            to="/"
            onClick={closeMenu}
            className="text-[#134806] block cursor-pointer no-underline"
          >
            Product
          </Link>
          <Link
            to="/about"
            onClick={closeMenu}
            className="text-[#134806] block cursor-pointer no-underline"
          >
            About Us
          </Link>
          <Link
            to="/contact"
            onClick={closeMenu}
            className="text-[#134806] block cursor-pointer no-underline"
          >
            Contact Us
          </Link>
        </div>
      )}
      <div className="hidden md:block cursor-pointer">
        <RouterLink
          to="/register"
          className="px-4 py-2 bg-[#47AB18] rounded-3xl text-white no-underline"
        >
          Register
        </RouterLink>
      </div>
    </nav>
  );
};

export default HomeTwentyoneNav;
